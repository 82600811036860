import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { TopHero } from '@cmp/sections'
import styles from './Formpage.module.scss'
import { MainLayout, Forms } from '@cmp/site'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { graphql } from 'gatsby'

export const query = graphql`
  query genericFormQuery($prismicId: ID!) {
    prismicFormTemplate(prismicId: { eq: $prismicId }) {
      prismicId
      data {
        form_title {
          text
        }
        formid {
          text
        }
        text {
          html
        }
        thank_you_page {
          url
        }
      }
    }
  }
`

export default ({ data }) => {
  const { language } = useLanguage()

  const {
    form_title: { text: title },
    text: { html: text },
    formid: { text: formid },
    thank_you_page: { url: thankYouPage },
  } = data.prismicFormTemplate.data

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <TopHero imageArr={[]} heading={title} />
        <Container className={styles.container}>
          <Row>
            <Col col={{ md: 10 }} offset={{ md: 1 }}>
              <article>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </article>
            </Col>
            <Col col={{ md: 7, lg: 4 }} offset={{ md: 1 }} className='pt-4'>
              <p className='eyebrows eyebrows--black'>Upplýsingar</p>
              <Forms formid={formid} thankYouPage={thankYouPage} />
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}
