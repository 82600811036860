import React from 'react'
import { MainLayout as Main } from '@cmp/site'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default () => {
  const { language } = useLanguage()

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <Main>
        <h1>404</h1>
        <p>Not found</p>
      </Main>
    </GlobalDataProvider>
  )
}
