import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import { MainLayout, SEO } from '@cmp/site'
import { seoPropertiesToProps } from '@src/utils'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'
import { PageDataContext } from '@src/context/PageDataContext'
import Authentication from '../Authentication/Authentication'

const Slices = makeSlices(sliceMapping)

export default ({ data: staticData, location }) => {
  const data = usePreview(staticData)

  const pageTitle = data.prismicPage.data.page_title ? data.prismicPage.data.page_title.text : ''
  const { setPageTitle } = useContext(PageDataContext)
  useEffect(() => {
    setPageTitle(pageTitle)
  }, [])

  const language = data.prismicPage.lang.substring(0, 2)
  const loginpage = data.prismicPage.data.loginpage?.document

  const altUrls = [
    ...data.prismicPage.alternate_languages.map(({ document }) => document),
    { url: location?.pathname, lang: language }
  ]

  const seoProperties = data.prismicPage.data.seo_properties
  const ogImage = data.prismicPage.data.override_default_image.url

  return (
    <Authentication
      loginpage={loginpage}
      language={language}
      seoProperties={seoProperties}
      altUrls={altUrls}
    >
      <GlobalDataProvider language={language} altUrls={altUrls}>
        <MainLayout overrideDefaultImage={ogImage}>
          {seoProperties && <SEO {...SEO.seoPropertiesToProps(seoProperties)} />}
          <article>
            <Slices body={data.prismicPage.data.body} />
          </article>
        </MainLayout>
      </GlobalDataProvider>
    </Authentication>
  )
}

export const query = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
      data {
        loginpage {
          document {
            ...prismicPageFragmentFull
          }
        }
      }
    }
  }
`
